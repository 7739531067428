import React, { useEffect } from "react"

//Components
import { Card, Image, Button, Link } from "components/anti"
import ModalGuestNotify from "@components/modal-component/modal-guest-notify"

//Assets
import imgProduct from "@public/img/sample/product.jpg"
import HeartPNG from "assets/img/icon/ico_heart.svg"
import HeartFillPNG from "assets/img/icon/ico_heart-fill.svg"

//Utils
import { currencyFormatter } from "lib/utils/utils"
import { useRouter } from "next/router"
import { F_M_ADD_TO_BAG, F_M_ADD_WISHLIST, F_M_REMIND_ME } from "lib/function"
import { useDispatch, useSelector } from "react-redux"
import {
  SET_CARTS,
  SET_WISHLIST,
  SET_ADD_CART,
  SET_PRODUCT_ADDED,
  SET_SHOW_LOGIN,
  SET_SHOW_TOAST,
  SET_TEXT_TOAST,
  SET_TYPE_TOAST,
} from "lib/redux/types"
import Cookies from "js-cookie"
import { useWindowSize } from "@components/hooks/hooks"

const CardProduct = ({
  img,
  imgAlt,
  noHeartIcon,
  outStock,
  timesIcon,
  id,
  title,
  sell_price,
  price,
  slug,
  skus,
  images,
  firstcategory,
  secondcategory,
  is_wishlist,
  is_new,
  callback,
  noCategory,
  manage_stock,
  className,
  imgHover,
  ...props
}) => {
  const modalGuestId = "modal-guest-card-product"

  const Router = useRouter()
  const { locale } = Router
  const { width } = useWindowSize()
  const isMobile = width < 768
  const dispatch = useDispatch()
  let token = Cookies.get(`${process.env.ACCESS_TOKEN}`)
  let tokenGuest = Cookies.get(`${process.env.GUEST_TOKEN}`)

  //States ************************************************************************************* //
  const { wishlist, user_data, wishlist_data } = useSelector(
    (state) => state.auth
  )

  const [state, setState] = React.useState({
    showModal: null,
    url: `/product-details/${slug + "-" + id}`,
    btnLoading: false,
    stock: skus[0]?.stock,
    is_wishlist: wishlist_data?.find((item) => item.id === id) ? true : false,
    is_new: is_new == 1,
    badgeClassName:
      firstcategory?.title === "New" || firstcategory?.title === "Terbaru"
        ? "New"
        : firstcategory?.title === "Sale"
        ? "Sale"
        : null,
    badge:
      firstcategory?.title === "New" || firstcategory?.title === "Terbaru"
        ? locale === "id"
          ? "Terbaru"
          : "New"
        : firstcategory?.title === "Sale"
        ? "Sale"
        : firstcategory?.title,
    images,
  })
  const [isStock, setIsStock] = React.useState(true)
  //States ************************************************************************************* //

  useEffect(() => {
    setState({
      url: `/product-details/${slug + "-" + id}`,
      btnLoading: false,
      stock: skus[0]?.stock,
      is_wishlist: wishlist_data?.find((item) => item.id === id) ? true : false,
      is_new: is_new == 1,
      badgeClassName:
        firstcategory?.title === "New" || firstcategory?.title === "Terbaru"
          ? "New"
          : firstcategory?.title === "Sale"
          ? "Sale"
          : null,
      badge:
        firstcategory?.title === "New" || firstcategory?.title === "Terbaru"
          ? locale === "id"
            ? "Terbaru"
            : "New"
          : firstcategory?.title === "Sale"
          ? "Sale"
          : firstcategory?.title,
      images,
    })
  }, [id, wishlist_data])

  //Functions ********************************************************************************** //
  const handleAddToCart = async () => {
    let skuId = skus[0].id
    let locale = Router.locale
    setState((prev) => ({ ...prev, btnLoading: true }))
    let { data, errors } = await F_M_ADD_TO_BAG(
      {
        sku_id: skuId,
        quantity: 1,
        lang: locale,
      },
      token ?? tokenGuest
    )
    if (data) {
      let { message } = data.addToCart
      if (message === "Success add item to cart") {
        dispatch({ type: SET_CARTS, payload: data.addToCart })
        dispatch({ type: SET_ADD_CART, payload: true })
        dispatch({
          type: SET_PRODUCT_ADDED,
          payload: {
            image: images[0],
            price: price,
            product_id: id,
            quantity: 1,
            sku_id: skuId,
            sell_price: sell_price,
            title: title,
            firstcategory: firstcategory?.title,
          },
        })
        setTimeout(() => dispatch({ type: SET_ADD_CART, payload: false }), 5000)
      } else {
        handleToast("error", message)
      }
    } else {
      handleToast("error", errors?.[0]?.message ?? errors.message)
    }
    setState((prev) => ({ ...prev, btnLoading: false }))
  }

  const handleWishlist = async () => {
    if (token) {
      let { data, errors } = await F_M_ADD_WISHLIST({ product_id: id })
      if (data) {
        if (state.is_wishlist) {
          setState((prev) => ({ ...prev, is_wishlist: false }))
          dispatch({ type: SET_WISHLIST, payload: wishlist - 1 })
          if (callback) return callback(id)
        } else {
          setState((prev) => ({ ...prev, is_wishlist: true }))
          dispatch({ type: SET_WISHLIST, payload: wishlist + 1 })
        }
        if (state.is_wishlist == 0)
          return dispatch({ type: SET_WISHLIST, payload: wishlist + 1 })
      } else {
        //!NEED ERROR HANDLING
        handleToast("error", errors?.[0]?.message ?? errors.message)
        // console.log(errors)
      }
    } else {
      dispatch({ type: SET_SHOW_LOGIN, payload: "modal-login-id" })
    }
  }

  const handleDeleteWishlist = async () => {
    let { data, errors } = await F_M_ADD_WISHLIST({ product_id: id })
    if (data) {
      dispatch({ type: SET_WISHLIST, payload: wishlist - 1 })
      if (callback) return callback(id)
    } else {
      //!NEED ERROR HANDLING
    }
  }

  const handleRemindMe = async () => {
    if (user_data) {
      setState((prev) => ({ ...prev, btnLoading: true }))
      let { data, errors } = await F_M_REMIND_ME({
        sku_id: skus[0].id,
        email: user_data.email,
      })
      setState((prev) => ({ ...prev, btnLoading: false }))
      if (data) {
        handleToast("success", data.addToNotify.message)
      } else {
        handleToast("error", errors?.[0]?.message ?? errors.message)
      }
    } else {
      setState((prev) => ({ ...prev, showModal: modalGuestId }))
      // dispatch({ type: SET_SHOW_LOGIN, payload: "modal-login-id" })
    }
  }

  const handleToast = (type, message, timeout = 3000) => {
    dispatch({ type: SET_SHOW_TOAST, payload: true })
    dispatch({ type: SET_TYPE_TOAST, payload: type })
    dispatch({ type: SET_TEXT_TOAST, payload: message })
    setTimeout(() => {
      dispatch({ type: SET_SHOW_TOAST, payload: false })
      dispatch({ type: SET_TYPE_TOAST, payload: null })
    }, timeout)
  }
  //Functions ********************************************************************************** //

  //React Operations *************************************************************************** //
  React.useEffect(() => {
    // console.log(skus, "SKUS")
    // console.log(props, "PROPS")
  }, [])
  //React Operations *************************************************************************** //

  return (
    <div className={`card-product ${className}`}>
      <ModalGuestNotify
        id={modalGuestId}
        show={state.showModal}
        hide={() => setState((prev) => ({ ...prev, showModal: null }))}
        sku_id={skus[0]?.id}
        token={tokenGuest}
      />
      <div className="card-product-image">
        {/* IMAGE AND STOCK VALIDATION ********************************** */}
        <div className="wrapper-img" onClick={() => Router.push(state.url)}>
          <div className="img-card">
            <Image
              src={state?.images ? state?.images?.[0] : imgProduct}
              alt={imgAlt}
              ratio="r-4-5"
            />
          </div>
          {state.stock === 0 ? (
            <div className="out-stock-overlay d-flex justify-content-center align-items-center">
              <p className="text-light p-0 m-0">
                {locale === "id" ? "stok habis" : "out of stock"}
              </p>
            </div>
          ) : null}
          {/* BADGE  */}
          {/* {state.badgeClassName ? (
            <div className={`badge ${state.badgeClassName.toLowerCase()}`}>
              {state.badge}
            </div>
          ) : null} */}
          <div className="badge-wrapper">
            {state.is_new && (
              <div className={`badge new`}>
                {locale === "id" ? "TERBARU" : "NEW"}
              </div>
            )}
            {price && sell_price < price && (
              <div className={`badge sale`}>
                {locale === "id" ? "OBRAL" : "SALE"}
              </div>
            )}
          </div>
          {/* BADGE */}
        </div>
        {/* IMAGE AND STOCK VALIDATION ********************************** */}
        {/* WRAPPER BUTTON ********************************************** */}
        <div className="bg-wrapper-button" />
        <div className="wrapper-button">
          {state.stock !== 0 ? (
            <Button
              size="lg"
              disabled={skus ? false : true}
              loader
              className={`w-100 px-3 ${
                state.btnLoading ? (locale === "id" ? "memuat" : "loading") : ""
              }`}
              title={locale === "id" ? "Tambah Keranjang" : "Add to Cart"}
              onClick={handleAddToCart}
            >
              {locale === "id" ? "Tambah Keranjang" : "Add to Cart"}
            </Button>
          ) : (
            <Button
              size="lg"
              disabled={skus ? false : true}
              loader
              className={`w-100 px-3 ${
                state.btnLoading ? (locale === "id" ? "memuat" : "loading") : ""
              }`}
              title={locale === "id" ? "Ingatkan Saya" : "Remind Me"}
              onClick={handleRemindMe}
            >
              {locale === "id" ? "Ingatkan Saya" : "Remind Me"}
            </Button>
          )}
          <Button
            size="lg"
            disabled={skus ? false : true}
            loader
            className={`w-100 px-3`}
            title={locale === "id" ? "Lihat Detail" : "View Detail"}
            link={state.url}
            variant="light"
          >
            {locale === "id" ? "Lihat Detail" : "View Detail"}
          </Button>
        </div>
        {/* WRAPPER BUTTON ********************************************** */}
      </div>

      {/* CARD BODY ***************************************************** */}
      <div className="card-product-wrapper">
        <div className="card-product-wrapper__left">
          {!noCategory && (
            <p className="category-title text-truncate-oneline">
              {firstcategory ? state.badge : "SAMPLE"}{" "}
            </p>
          )}
          <h5 className="card-product-title text-truncate-twoline">
            {title ?? "Sagres Dining Chair Black"}
          </h5>
          <div className="card-product-price">
            <h6
              className={`grand-total ${
                price && sell_price < price && "text-danger"
              }`}
            >
              {sell_price ? currencyFormatter("ID", sell_price) : "RP 300.000"}
            </h6>
            {price && sell_price < price && (
              <h6 className="subtotal">{currencyFormatter("ID", price)}</h6>
            )}
          </div>
        </div>
        {/* WISHLIST ICON  */}
        {noHeartIcon ? null : (
          <div className="icon-wrapper" onClick={handleWishlist}>
            <Image
              src={state.is_wishlist ? HeartFillPNG : HeartPNG}
              alt={"Icon"}
              className="icon-heart"
            />
          </div>
        )}
        {/* WISHLIST ICON  */}
      </div>
      {/* CARD BODY ***************************************************** */}
    </div>
  )
}

export default CardProduct
